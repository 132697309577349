.label {
  margin-right: 4px;
  font-weight: 500;
  letter-spacing: 1.4px;
  font-size: 14px;
}

.value {
  font-size: 14px;
  width: 100%;
  padding-left: 4px;
}

.label-col {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: end;
  padding-right: 8px;
}

.value-col {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  border-bottom: 1px solid #3fe6d8;
  border-left: 2px dotted #3fe6d8;
}
