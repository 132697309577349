.card {
  position: relative;
  padding: 16px;
  border-radius: 12px;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease-in-out !important;

  &:hover {
    transform: scale(1.05);
  }

  &:hover .icon {
    fill: #f92096;
  }
}

.price {
  color: #000 !important;
  text-align: center;
  font-weight: 700 !important;
  font-size: 2.5em !important;
  display: inline;
}

.monthly {
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  display: inline;
}

.subtitle-price {
  color: #4a5568 !important;
  font-size: 1em !important;
  font-weight: 400 !important;
  text-align: center;
}

.list-item {
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 1.05em !important;
  padding-left: 0px !important;
}


.button-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 75px;
}
