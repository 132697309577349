.success {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: green;
}

.error {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: red;
}

.text {
    font-size: 14px!important;
}
