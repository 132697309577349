body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e8eaee !important;
  /* overflow: hidden; */
}

html {
  height: 100%;
}
body {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Nasalization";
  src: url(./fonts/nasalization.otf);
}
@font-face {
  font-family: "Good Times";
  src: url(./fonts/goodtimes.otf);
}

a:link {
  color: white;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: white;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
